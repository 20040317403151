@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Roobert-Bold";
  src: url("./assets/fonts/Roobert-Bold.otf");
}
@font-face {
  font-family: "Roobert-Heavy";
  src: url("./assets/fonts/Roobert-Heavy.otf");
}
@font-face {
  font-family: "Roobert-Light";
  src: url("./assets/fonts/Roobert-Light.otf");
}
@font-face {
  font-family: "Roobert-Medium";
  src: url("./assets/fonts/Roobert-Medium.otf");
}
@font-face {
  font-family: "Roobert-Regular";
  src: url("./assets/fonts/Roobert-Regular.otf");
}
@font-face {
  font-family: "Roobert-SemiBold";
  src: url("./assets/fonts/Roobert-SemiBold.otf");
}

@layer components {
  .dark-Btn {
    background: #18374f;
    color: #ffffff;
    border: none;
    font-size: 22px;
    line-height: 31px;
  }
  .dark-Btn:hover {
    background: #18374f;
    color: #ffffff !important;
  }
  .white-Btn {
    border-radius: 50px;
    border: 1px solid var(--Stroke-color, #a5c5cc);
    background: var(--White, #fff);
    color: var(--Dark-blue, #134357);
    font-family: Roobert;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .light-Btn {
    background: #acdce7;
    color: #18374f;
    border: none;
    font-size: 22px;
    line-height: 31px;
    box-shadow: 10px 1px 20px 0px rgba(165, 197, 204, 0.2);
  }
  .light-Btn:hover {
    background: #acdce7;
    color: #18374f !important;
  }
  .transparent-btn:hover {
    background: transparent;
    color: #18374f !important;
    /* border:1px solid #A5C5CC; */
  }
  :where(.css-dev-only-do-not-override-2i2tap).ant-btn-default:not(
      :disabled
    ):not(.ant-btn-disabled):hover {
    border: none !important;
  }
}
:root {
  --secondary: #18374f;
  --primary: #134357;
  --light: #d7eef3;
  --strokeColor: #acdce7;
  --border: #a5c5cc;
  --success: #d9f2dd;
  --danger-light: #ffc4b7;
  --accentBlue: #6db9d0;
}
