@import url("https://fonts.googleapis.com/css2?family=Carter+One&family=Didact+Gothic&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;600;700;800&display=swap");

*,
*::before,
*::after {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

@font-face {
  font-family: CenturyGothicRegular;
  src: url("./assets/fonts/centuryGothicRegular.ttf");
}

@font-face {
  font-family: CenturyGothicBold;
  src: url("./assets/fonts/centuryGothicBold.ttf");
}

body {
  font-family: CenturyGothicRegular;
}

.image::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
}

.hover-effect {
  border-radius: 12px 0px 0px 12px;
}

.recharts-legend-item {
  display: none !important;
}

.recharts-text {
  font-size: 16px !important;
  font-family: Roobert-Regular;
  fill: #18374f;
}
.recharts-pie-label-text {
  fill: #18374f;
}

.recharts-pie-label-line {
  stroke: #046997 !important;
}

/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  display: none;
} */

/* input:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-text-fill-color: white;
} */

.blur1 {
  opacity: 0.4;
}

.hover-effect:hover .logout-icon {
  fill: #046997;
}

.scrollbar-container {
  overflow-y: scroll !important;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #a0aec0 transparent; /* For Firefox */
}

/* For Webkit-based browsers like Chrome and Safari */
.scrollbar-container::-webkit-scrollbar {
  width: 2px;
}

.scrollbar-container::-webkit-scrollbar-track {
  background-color: transparent;
}

.scrollbar-container::-webkit-scrollbar-thumb {
  background-color: #a0aec0;
  border-radius: 1px;
}

.scrollbar-containerX {
  overflow-x: scroll !important;
  scrollbar-width: thin;
  scrollbar-color: #a0aec0 transparent;
}

/* For Webkit-based browsers like Chrome and Safari */
.scrollbar-containerX::-webkit-scrollbar {
  width: 5px; /* Adjust the width as needed */
  height: 3px;
}

.scrollbar-containerX::-webkit-scrollbar-track {
  background-color: transparent;
}

.scrollbar-containerX::-webkit-scrollbar-thumb {
  background-color: #18374f !important;
  border-radius: 2.5px; /* Adjust the border-radius as needed */
}

.scrollbar-containerY {
  overflow-y: scroll !important;
  scrollbar-width: thin;
}

/* For Webkit-based browsers like Chrome and Safari */
.scrollbar-containerY::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}

.scrollbar-containerY::-webkit-scrollbar-track {
  background-color: #18374f;
}

.scrollbar-containerY::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 2.5px; /* Adjust the border-radius as needed */
}

.ant-select-selector {
  border-radius: 30px !important;
}

.ant-select-selection-item {
  color: #18374f;
}

.ant-select-arrow {
  position: relative;
  right: 20px !important;
}
.signup .ant-form-item-control-input-content input {
  border-radius: 30px !important;
}
.signup
  :where(.css-dev-only-do-not-override-2i2tap).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border: none;
  box-shadow: 0px 6px 9px 0px rgba(165, 197, 204, 0.1);
}
.signup
  :where(.css-dev-only-do-not-override-2i2tap).ant-select-single:not(
    .ant-select-customize-input
  )
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 100% !important;
}
.signup
  .select-country
  :where(.css-dev-only-do-not-override-2i2tap).ant-select
  .ant-select-selection-item {
  flex: initial !important;
  /* margin-left: 40px !important; */
  color: #a3a3a3;
}
.phone-number input {
  height: 41px;
}
.phone-number .ant-form-item-control-input-content input {
  border-radius: 0px !important;
  border-top-right-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}
.phone-number :where(.css-dev-only-do-not-override-2i2tap).ant-input {
  border: none !important;
}
.phone-number .ant-input-group-addon {
  height: 40px;
  background-color: #ffffff;
  border-radius: 30px;
  border: none !important;
}
.signup
  :where(.css-dev-only-do-not-override-2i2tap).ant-steps
  .ant-steps-item-title::after {
  display: none !important;
}

.ant-modal-close-x {
  display: none !important;
}
.seasonaldocks .ant-modal-content {
  background-color: #e2f3f7 !important;
}
.ant-modal .ant-modal-content {
  padding: 0px !important;
}
.seasonaldocks .slick-prev,
.slick-next {
  height: 50px !important;
  width: 50px !important;
}
.seasonaldocks .slick-prev {
  position: absolute;
  left: -50px;
}
.seasonaldocks .slick-next {
  position: absolute;
  right: -50px;
}

.seasonaldocks .slick-list {
  margin: 0 -10px;
}
.seasonaldocks .slick-slide > div {
  padding: 0 10px;
}

.edit-user .ant-form-item-control-input-content input {
  border-radius: 30px !important;
}
.edit-user input {
  padding-left: 36px;
  color: #18374f;
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  font-family: "Roobert-Regular";
}
.seasonaldocks .ant-modal-body {
  height: 80vh;
  overflow-y: auto;
}
.club-members .ant-modal-content {
  background-color: #e2f3f7 !important;
  border-radius: 20px;
}
.waitingList .ant-select-selection-item {
  color: #ffffff !important;
  font-family: "Roobert-Regular";
  font-size: 20px;
}
textarea {
  resize: none !important;
}

.email-select .ant-select-selector {
  text-align: left;
  padding-left: 20px !important;
}
.rounded-checkbox .ant-checkbox-inner {
  border-radius: 100%;
  outline: none !important;
  border-color: none !important;
}
.rounded-checkbox
  :where(.css-dev-only-do-not-override-2i2tap).ant-checkbox-wrapper:not(
    .ant-checkbox-wrapper-disabled
  ):hover
  .ant-checkbox-inner,
:where(.css-dev-only-do-not-override-2i2tap).ant-checkbox:not(
    .ant-checkbox-disabled
  ):hover
  .ant-checkbox-inner {
  border-color: transparent !important;
  outline: none !important;
}
.rounded-checkbox
  :where(.css-dev-only-do-not-override-2i2tap).ant-checkbox-checked
  .ant-checkbox-inner {
  border-color: transparent !important;
  background-color: #134357 !important;
}
.rounded-checkbox
  :where(.css-dev-only-do-not-override-2i2tap).ant-checkbox-checked
  .ant-checkbox-inner:after {
  transition: none;
  transform: none;
  display: none;
}

.data-reach-combobox-input {
  height: 6px !important;
}

.search {
  height: 60px !important;
}
.add-asset-search input {
  height: 50px;
  background: #fff;
  border-radius: 50px;
  width: 40%;
  padding-left: 35px;
  margin-left: 17px;
  position: absolute;
  top: 65px;
  z-index: 10;
  color: #b5b5b5;
  font-family: "Roobert-Regular";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 31px;
}
.search input {
  height: 50px;
  background: #fff;
  border-radius: 50px;
  width: 40%;
  padding-left: 35px;
  margin-left: 17px;
  position: relative;
  top: 80px;
  z-index: 10;
  color: #b5b5b5;
  font-family: "Roobert-Regular";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 31px;
}
.background {
  background: var(
    --background-gradient-light,
    linear-gradient(180deg, #fffdf6 0%, #e1f3f6 80.73%)
  );
}
.carousel-img .ant-image-mask {
  border-radius: 10px;
}
.signup .ant-select-selection-item {
  color: black !important;
}
.ant-pagination-options {
  display: none !important;
}

.signin {
  background: var(
    --background-gradient-light,
    linear-gradient(180deg, #fffdf6 0%, #e1f3f6 80.73%)
  );
}

.signin :where(.css-dev-only-do-not-override-2i2tap).ant-input:focus-within {
  box-shadow: none !important;
}
.signin input {
  box-shadow: none !important;
  border-bottom: 1px solid #134357;
  border-radius: 0px !important;
  outline: 0px !important;
}

.dashboard .ant-table-container table > thead > tr:first-child > *:last-child {
  border-start-end-radius: 30px !important;
  border-end-end-radius: 30px !important;
}

/* antd-table-file */

/* index file */

.ant-select-selector {
  min-width: 80px !important;
  text-align: center;
}
body {
  background: var(
    --background-gradient-light,
    linear-gradient(180deg, #fffdf6 0%, #e1f3f6 80.73%)
  );
}
.signup-bg {
  background: var(
    --background-gradient-light,
    linear-gradient(180deg, #fffdf6 0%, #e1f3f6 80.73%)
  );
}

.signup
  :where(.css-dev-only-do-not-override-2i2tap).ant-btn-default:not(
    :disabled
  ):not(.ant-btn-disabled):hover {
  color: inherit;
}

/* .signup .ant-steps-item {
  padding-inline-start: 0px !important;
} */
.signup
  :where(.css-dev-only-do-not-override-2i2tap).ant-steps
  .ant-steps-item-process
  .ant-steps-item-icon {
  background-color: #a5c5cc !important;
  border-color: #a5c5cc !important;
}
.ant-steps-item-tail {
  display: inline !important;
  position: relative;
  left: 44px !important;
  top: 22px !important;
  z-index: 0 !important;
}
.ant-steps-item {
  padding-inline-start: 0px !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0 !important;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.ant-steps-item-content {
  display: none !important;
}
.ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-tail {
  display: none !important;
}

/* calendar */
.fc-icon {
  position: absolute;
  right: 17px;
  margin-top: 27.5px;
}
.fc-icon fc-icon-plus-square {
  margin-top: 40px;
}
.fc-theme-standard td {
  border: none !important;
}

tr td .odd-date {
  background: #f8f8f8 !important;
}
tr td .even-date {
  background: #fff !important;
}
.fc-theme-standard th {
  border: none;
}
.fc .fc-resource-timeline-divider {
  cursor: col-resize;
  width: 3px;
  background: white;
}
.fc-theme-standard .fc-scrollgrid {
  border: none;
}
.fc-timeline-header-row th {
  border-left: 1px solid #a5c5cc;
  border-right: 1px solid #a5c5cc;
}
.fc .fc-datagrid-cell-cushion {
  padding: 0px !important;
}

.fc-scroller-harness {
  background-color: #fff !important;
}
.fc-datagrid-cell-frame {
  height: 30px;
  /* border-bottom: 1px solid grey !important; */
}
.fc-datagrid-expander-placeholder {
  display: none !important;
}
.fc-event {
  border-radius: 10px !important;
}
.fc-toolbar-chunk {
  display: none !important;
}
.ant-modal-close {
  display: none !important;
}
.ReactModal__Overlay .ReactModal__Overlay--after-open {
  z-index: 50 !important;
}
.ReactModalPortal {
  position: relative !important;
  z-index: 50 !important;
}
.waitingList .ant-select-selection-item {
  color: #18374f !important;
  font-size: 16px !important;
  font-family: Roobert-SemiBold;
}
.ant-collapse-item {
  border-bottom: none !important;
}
.ant-collapse {
  border: none !important;
  background: transparent;
}
.ant-collapse-content {
  border-radius: 0px !important;
  border-top: none !important;
  background: transparent !important;
}

.rdrMonth {
  width: 100% !important;
}

.rdrCalendarWrapper {
  width: 100% !important;
  font-size: 14px !important;
}

.rdrDay .rdrDayNumber span {
  color: #134357 !important;
}

#booking .rdrCalendarWrapper {
  font-size: 16px !important;
  color: #134357 !important;
}

.rdrDay.rdrDayDisabled .rdrDayNumber span {
  color: #aeb9bf !important;
}

#booking .rdrDay {
  color: #134357 !important;
}

.ant-picker-panel .ant-picker-ok button {
  background-color: "#acdce7" !important;
  color: #134357;
}

#dateInput .rdrCalendarWrapper {
  position: absolute;
  top: 2.3rem;
  border-radius: 8px !important;
  box-shadow: 10px 1px 20px 0px rgba(165, 197, 204, 0.2) !important;
}

.rdrCalendarWrapper {
  border-top-right-radius: 20px !important;
  border-top-left-radius: 20px !important;
  font-size: 16px !important;
}

.rdrDay.rdrDayDisabled {
  background-color: transparent !important;
}

.rdrDay.rdrDayDisabled .rdrDayNumber {
  border-radius: 20px !important;
  margin-left: 12px;
}

.rdrDay.rdrDayDisabled .rdrDayNumber span {
  color: #134357 !important;
  opacity: 0.2;
}

#bookingTimer .ant-picker-input > input {
}
#bookingTimer .ant-picker-input > input {
  border-radius: 9999px;
  background-color: #fff;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  text-align: center;
  font-family: Roobert;
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: #134357;
  outline: 2px solid transparent;
  outline-offset: 2px;
  font-family: "Roobert-Regular";
}

.ant-picker-clear {
  display: none !important;
}

#forgot-password input {
  border-bottom: 1px solid #134357;
}

.add-asset .ant-form-item .ant-form-item-label {
  text-align: start !important;
}
.add-asset .ant-row {
  display: flex !important;
  flex-direction: column !important;
}

#chatBookings
  :where(.css-dev-only-do-not-override-m4timi).ant-carousel
  .slick-prev,
:where(.css-dev-only-do-not-override-m4timi).ant-carousel .slick-next {
  opacity: 100%;
  color: #134357;
  margin-top: 0px !important;
}

#chatBookings
  :where(.css-dev-only-do-not-override-dkbvqv).ant-carousel
  .slick-prev::before {
  color: #134357;
  font-size: 24px !important;
  opacity: 100% !important;
}
#chatBookings
  :where(.css-dev-only-do-not-override-dkbvqv).ant-carousel
  .slick-next::before {
  color: #134357;
  opacity: 100% !important;
  font-size: 24px !important;
}
#chatBookings .slick-disabled::before {
  display: none;
}
#chatBookings
  :where(.css-dev-only-do-not-override-dkbvqv).ant-carousel
  .slick-prev,
:where(.css-dev-only-do-not-override-dkbvqv).ant-carousel .slick-next {
  top: 60% !important;
}
