.dashboard .ant-table-thead .ant-table-cell {
  background-color: #d7eef3 !important;
  color: #18374f !important;
  text-align: center !important;
  font-family: "Roobert-Regular" !important;
  height: 45px !important;
}

.dashboard .ant-table-thead > tr > th {
  padding: 0px !important;
}
.dashboard .ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 5px 5px !important;
}
.dashboard .ant-table-container table > thead > tr:first-child > *:first-child {
  border-start-start-radius: 0px !important;
}
.dashboard .ant-table-row {
  box-shadow: 10px 1px 20px 0px rgba(165, 197, 204, 0.2) !important;
  border-radius: 0px 50px 50px 0px !important;
  margin-top: 10px !important;
  text-align: center !important;
  font-size: 16px !important;
  font-family: "Roobert-Light";
}

.my-table .ant-table-thead > tr > th {
  padding: 5px !important;
}
.dashboard
  :where(.css-dev-only-do-not-override-2i2tap).ant-table-wrapper
  .ant-table-tbody
  > tr::before {
  margin-top: 10px !important;
  border-collapse: 20px !important;
}
.dashboard .ant-table-wrapper .ant-table-tbody > tr > td {
  border: none !important;
  border-bottom: none !important;
}
.dashboard .ant-table-cell-row-hover {
  background: none !important;
}
.dashboard
  :where(.css-dev-only-do-not-override-2i2tap).ant-table-wrapper
  .ant-table-tbody
  .ant-table-row
  > .ant-table-cell-row-hover {
  background: transparent !important;
}
.ant-table table {
  border-spacing: 0 10px !important;
}
.my-table .ant-table-thead .ant-table-cell {
  background-color: transparent !important;
  color: #18374f !important;
  font-size: 16px !important;
  font-family: "Roobert-SemiBold" !important;
  border-bottom: 1px solid #a5c5cc !important;
  text-align: center;
}
.my-table .ant-table-row .ant-table-cell {
  text-align: center;
}
.my-table .ant-table table {
  border-spacing: 0px !important;
}
.my-table .ant-table-cell::before {
  display: none !important;
}
.my-table .ant-table-tbody > tr > td {
  font-family: "Roobert-Regular" !important;
  font-size: 16px !important;
  border-bottom: 1px solid #f0f0f0 !important;
}
.my-table .ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 5 !important;
}
.my-table .ant-checkbox-checked .ant-checkbox-inner:after {
  display: none !important;
}

.my-table .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #18374f !important;
  border-color: #18374f !important;
}

.my-table .ant-checkbox-inner:after {
  background-color: #18374f !important;
}
.my-table .ant-table-row {
  font-size: 16px !important;
  font-family: "Roobert-Light" !important;
}
.import-table
  :where(.css-dev-only-do-not-override-2i2tap).ant-table-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:last-child {
  border-start-end-radius: 30px !important;
}

.import-table .ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 0px !important;
}
.import-table .ant-table table {
  border-spacing: 0px !important;
}
.import-table
  :where(.css-dev-only-do-not-override-2i2tap).ant-table-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:first-child {
  border-start-start-radius: 30px !important;
}
.import-table .ant-table-wrapper .ant-table-tbody > tr > td {
  border: none !important;
  transition: none;
}
.import-table .ant-table-thead .ant-table-cell {
  background-color: #d7eef3 !important;
  color: #18374f !important;
  text-align: center !important;
  font-family: "Roobert-Regular";
  height: 45px !important;
}
.import-table .ant-form-item {
  margin-bottom: 0px !important;
}
.import-table .ant-table {
  background: transparent !important;
}
.import-table .ant-form-item-explain-error {
  display: none !important;
}
.table-list .ant-table-selection-column {
  display: none !important;
}

.table-list .ant-table-thead .ant-table-cell {
  background-color: transparent !important;
  color: #18374f;
  font-size: 16px !important;
  font-family: "Roobert-SemiBold";
  text-align: center;
  border-bottom: 1px solid #a5c5cc !important;
}
.table-list .ant-table-row .ant-table-cell {
  text-align: center !important;
}
.table-list .ant-table table {
  border-spacing: 0px !important;
}
.table-list .ant-table-cell::before {
  display: none !important;
}
.table-list .ant-table-tbody > tr > td {
  font-family: "Roobert-Regular";
  font-size: 16px !important;
  border-bottom: 1px solid #f0f0f0 !important;
  color: #18374f;
}
.table-list
  :where(.css-dev-only-do-not-override-2i2tap).ant-table-wrapper
  .ant-table-tbody
  .ant-table-row.ant-table-row-selected
  > .ant-table-cell {
  background: #e2f3f7 !important;
}
.table-list
  :where(.css-dev-only-do-not-override-2i2tap).ant-table-wrapper
  .ant-table-tbody
  .ant-table-row
  > .ant-table-cell-row-hover {
  background: none !important;
}

#personal-booking .ant-table-thead .ant-table-cell {
  display: none;
}
